import React from 'react'
import Link from 'gatsby-link'
import Logo from './aga-logo.svg'

export default class SiteHeader extends React.Component {
  render() {
    return (
      <div>
        <header className="aga-site-header">
          <Link to="/"><img className="aga-header-logo" src={ Logo } alt="AGA logo" /></Link>
          <nav className="aga-header-nav">
            <Link to="/">Home</Link>
            <Link to="/cases">Cases</Link>
            <Link to="/media">Media</Link>
            <Link to="/faculty">Faculty</Link>
            <Link to="/about">About</Link>
            <Link to="/cme">CME</Link>
          </nav>
        </header>
      </div>
    )
  }
}
