import React from 'react'
import Link from 'gatsby-link'

class Nav extends React.Component {
  render() {
    return (
      <div>
        <footer className="aga-footer">
          <nav className="aga-footer-toolbar">
            <div className="aga-footer-item"><Link to="/about">About</Link></div>
            <div className="aga-footer-item"><a href="https://www.gastro.org/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy</a></div>
          </nav>
        </footer>
      </div>
    )
  }
}

export default Nav

