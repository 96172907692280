import React from 'react'

import Logo from './aga-logo.svg'
import Facebook from './facebook-2.svg'
import Twitter from './twitter.svg'
import LinkedIn from './linkedin-2.svg'
import YouTube from './youtube.svg'

export default class BigFooter extends React.Component {
  render() {
    return (
      <div>
        <header className="aga-big-footer-container">
          <a href="https://www.gastro.org" target="_blank" rel="noopener noreferrer">
            <img className="aga-big-footer-logo" src={ Logo } alt="AGA Homepage Link" />
          </a>
          <nav className="aga-big-footer-nav">
            <a className="aga-social-icon" target="_blank" href="https://www.facebook.com/AmerGastroAssn/" rel="noopener noreferrer">
              <img src={ Facebook } alt="AGA Facebook Page Link" />
            </a>
            <a className="aga-social-icon" target="_blank" href="https://twitter.com/amergastroassn/" rel="noopener noreferrer">
              <img src={ Twitter } alt="AGA Twitter Page Link"/>
            </a>
            <a className="aga-social-icon" target="_blank" href="https://www.linkedin.com/company/american-gastroenterological-association-aga-/" rel="noopener noreferrer">
              <img src={ LinkedIn } alt="AGA LinkedIn Page Link"/>
            </a>
            <a className="aga-social-icon" target="_blank" href="https://www.youtube.com/user/AmerGastroAssn/" rel="noopener noreferrer">
              <img src={ YouTube } alt="AGA Youtube Channel Link" />
            </a>
          </nav>
        </header>
      </div>
    )
  }
}
