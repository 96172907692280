import React from 'react'
import Helmet from 'react-helmet'

import socialBanner from './aga-social.jpg'
import appleTouch from './apple-touch-icon.png'
import faviconSmallPNG from './favicon-16x16.png'
import faviconLargePNG from './favicon-32x32.png'
import favicon from './favicon.ico'

export default class Metadata extends React.Component {
  
  render() {
    return (
      <div>
        <Helmet htmlAttributes={{ lang : 'en' }}>
          <title>AGA CME</title>

          <meta name='description' content="The American Gastroenterological Association (AGA) is the trusted voice of the GI community. AGA publishes top-tier journals, guidelines and patient education materials, provides research funding opportunities, and ensures GIs have a voice when it comes to policies that affect them and their patients." />
          <meta name='keywords' content='gastroenterology' />
          <meta property='og:image' content={ socialBanner } />
          <meta property='og:locale' content='en_US' />
          <meta property='og:type' content='website' />
          <meta property='og:title' content='AGA Site' />
          <meta property='og:description' content="The American Gastroenterological Association (AGA) is the trusted voice of the GI community. AGA publishes top-tier journals, guidelines and patient education materials, provides research funding opportunities, and ensures GIs have a voice when it comes to policies that affect them and their patients." />
          <meta property='og:url' content='http://www.gastro.org' />
          <meta property='og:updated_time' content='2018-06-21' />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:site' content='@AmerGastroAssn' />
          <meta name='twitter:title' content='AGA CME Site' />
          <meta name='twitter:description' content="The American Gastroenterological Association (AGA) is the trusted voice of the GI community. AGA publishes top-tier journals, guidelines and patient education materials, provides research funding opportunities, and ensures GIs have a voice when it comes to policies that affect them and their patients." />
          <meta name='twitter:image' content={ socialBanner } />
          <meta name='twitter:image:alt' content='AGA CME' />

          <link rel="apple-touch-icon" sizes="180x180" href={ appleTouch } />
          <link rel="icon" type="image/png" sizes="32x32" href={ faviconLargePNG } />
          <link rel="icon" type="image/png" sizes="16x16" href={ faviconSmallPNG } />
          <link rel="shortcut icon" href={ favicon } />

          <script type="application/ld+json">{`
            "@context": "http://schema.org",
            "@type":"WebSite",
            "name":"American Gastroenterological Association (AGA) CME Site",
            "url":"https://www.gastro.org/"
          `}</script>
          <script type="application/ld+json">{`
            "@context":"https://schema.org",
            "@type":"Organization",
            "name":"American Gastroenterological Association (AGA)",
            "url":"https://www.gastro.org/",
            "sameAs":[ 
              "https://www.facebook.com/AmerGastroAssn",
              "https://twitter.com/AmerGastroAssn",
              "https://www.linkedin.com/company/american-gastroenterological-association-aga-"
            ]
          `}</script>
          <link rel="canonical" href="https://www.gastro.org" />

          <link rel="stylesheet" href="https://use.typekit.net/xwq7jno.css" /> 
        </Helmet>
      </div>
    )
  }
}



