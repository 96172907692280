import React from 'react'
import Link from 'gatsby-link'
import ReactModal from 'react-modal'

// Attached React Modal to gatsby parent element, for accessibility
// See here: http://reactcommunity.org/react-modal/accessibility/
ReactModal.setAppElement('#___gatsby')

// Overriding default ReactModal styles
const customModalStyles = {
  content : {
    backgroundColor       : 'rgb(18,32,51)',
    border                : '2px solid white',
    borderRadius          : 'none',
    color                 : 'white',       
    height                : '240px',
    left                  : '0',
    right                 : '0',
    marginLeft            : 'auto',
    marginRight           : 'auto',
    textAlign             : 'center',
    width                 : '300px'
  },
  overlay : {
    backgroundColor       : 'rgba(18,18,18,0.9)'
  }
};

class Nav extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div>
        <nav className="aga-toolbar">
          <section className="aga-logo">
            <div><Link to="/">Better IBD Care</Link></div>
          </section>
          <nav className="aga-nav-toolbar">
            <div className="aga-nav-item" onClick={this.handleOpenModal}>Login</div>
          </nav>
          <ReactModal 

            aria={{
              labelledby: "Login Modal Window",
              describedby: "Login Authentication for AGA CME"
            }}
            contentLabel="Login Modal Window"
            closeTimeoutMS={200}            
            isOpen={this.state.showModal}
            role="dialog"
            shouldCloseOnEsc={true}
            style={customModalStyles}
          >
            <div className="aga-login-modal">
              <p>
                <a className="aga-button-regular lightest-gray-bg aga-modal-close" 
                   href="https://agacommunications.auth0.com/login?state=g6Fo2SBfRWlyWUxLbmFjaG1XSFNud1N6U3lpTW9QVzV2VmlzcKN0aWTZMmdhRm8yU0E0YlhJd2EzSnFOalJZWXpGWWRIZG1hREp3UkMxNVEweHRWMlYzUzBSS2F3o2NpZNkgSkVrZGt3cFFkc0hBaE9Yc2FORzFYNFgzMXFIblpoa3Q&client=JEkdkwpQdsHAhOXsaNG1X4X31qHnZhkt&protocol=samlp&RelayState=http%3A%2F%2Fagau.gastro.org%2Fdiweb%2Fmylearning" target="_blank" rel="noopener noreferrer">
                    Login / Join Gastro.org
                </a>
              </p>
              <div className="aga-button-regular lightest-gray-bg aga-modal-close" 
                   onClick={this.handleCloseModal}>
                   Close
              </div>
            </div>
          </ReactModal>
        </nav>
      </div>
    )
  }
}

export default Nav
