import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"

// Get styles, base stuff and sass
import '../../styles/main.scss'

import Metadata from '../Metadata'
import Nav from '../Nav'
import SiteHeader from '../SiteHeader'
import Footer from '../Footer'
import BigFooter from '../BigFooter'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Metadata 
          title={ data.site.siteMetadata.title }
        />
        <Nav />
        <SiteHeader />
        <div>
          { children }
        </div>
        <BigFooter />
        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
